/* General Styles */
.content-home {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    max-width: 1400px; /* Assegura que a largura ocupe toda a tela */
    margin: 0 auto;
    text-align: center;
    background-color: #ffffff;
}

/* Home Section */
.home {
    padding: 50px 200px;
    margin: 0;
    background-color: #ffffff; /* Cor de fundo branca */
    box-shadow: none; /* Remove a sombra para garantir continuidade */
    border-bottom: 0; /* Remover borda para evitar a separação */
}

.home h1 {
    font-size: 2.5rem;
    color: #006494;
    margin-bottom: 20px;
    font-weight: bold;
}

.home p {
    font-size: 1.5rem;
    color: #555;
    line-height: 1.8;
    margin-bottom: 20px;
}

.home a {
    color: #006494;
    text-decoration: underline;
    font-weight: bold;
}

.home a:hover {
    color: #ff4500;
    text-decoration: none;
}

/* Imagens em .servico-imagem */
.servico-imagem {
    max-width: 100%; /* Assegura que a largura máxima respeita o contêiner */
    height: 50%;    /* Mantém a proporção original */
    object-fit: contain; /* Garante que a imagem não seja cortada */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: block; /* Garante alinhamento consistente */
    margin: 0 auto; /* Centraliza as imagens */
    border-radius: 100px;
}
/* Serviços Section */
.servicos {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 50px 0;
    text-align: center;
    color: #555;
}

.servicos h2{
    font-size: 2.5rem;
    color: #006494;
    margin-bottom: 20px;
    font-weight: bold;
}

.servico {
    display: flex;
    flex-wrap: wrap; /* Permite que o layout se adapte a telas menores */
    align-items: center;
    margin-bottom: 40px;
    gap: 20px;
}

.servico-texto {
    flex: 1;
    font-size: 1.5rem;
    line-height: 1.8;
    color: #555;
    text-align: left;
    padding: 20px 100px 20px 100px;
}





/* Posto Autorizado */
.posto {
    justify-content: center;
    padding: 50px 200px;
    margin: 0;
    text-align: center;
    background-color: #ffffff;
    box-shadow: none; /* Remove a sombra para continuidade */
    border-bottom: 0; /* Remover borda para evitar a separação */
    color: #555;
}

.sulpack-image,
.alt-image {
    margin-top: 10px;
    max-width: 100%;
    height: auto;
    margin-right: 60px;
}

.alt-image {
    margin-right: 0;
}


/* Mobile Responsiveness */
@media (max-width: 768px) {
    .home {
        padding: 20px; /* Reduz o padding para telas menores */
    }

    .home h1 {
        font-size: 2rem; /* Tamanho do título reduzido */
    }

    .home p {
        font-size: 1rem; /* Fonte menor para textos */
    }
     
    .dentista-imagem {
        max-width: 80%; /* Ajusta o tamanho da imagem */
        height: auto;   /* Garante que a altura seja proporcional */
        margin-bottom: 20px; /* Adiciona espaço abaixo da imagem */
    }

    .posto {
        padding: 20px; /* Padding reduzido na seção "Posto Autorizado" */
    }

    .servicos {
        padding: 20px;
        flex-direction: column; /* Organiza os serviços em coluna */
        align-items: center; /* Centraliza os serviços */
    }

    .servico {
        flex-direction: column; /* Elementos empilhados */
        align-items: center; /* Centraliza os elementos */
        gap: 20px; /* Espaçamento entre os itens */
    }

    .servico-texto {
        font-size: 1rem; /* Texto menor para telas pequenas */
        text-align: center; /* Centralização do texto */
    }

    .servico-imagem {
        max-width: 90%; /* Limita a largura das imagens */
    }

    .sulpack-image,
    .alt-image {
        max-width: 150px; /* Ajusta tamanho das imagens no "Posto Autorizado" */
    }
}
