html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh; 
}

.App .content {
    flex: 1; 
}

.footer {
    background-color: #006494;  
    color: white;
    text-align: center;
    padding: 10px 20px;
    font-size: 0.9rem;
    box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.1);
}
