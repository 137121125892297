.service-request-form-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 50px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centraliza os elementos horizontalmente */
}

.service-request-form-container h2 {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
}

.service-request-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.service-request-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.service-request-form input,
.service-request-form textarea,
.service-request-form select { /* Atualizado para incluir o select */
  width: 100%;
  max-width: 4500px; /* Limita a largura das caixas de texto e select */
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box; /* Garante que o padding e a borda não aumentem o tamanho da caixa */
}

.service-request-form textarea {
  resize: none;
}

.service-request-form button {
  width: 100%;
  max-width: 4500px; /* Limita a largura do botão */
  padding: 10px;
  background-color: #1B98E0;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  box-sizing: border-box; /* Garante que o padding e a borda não aumentem o tamanho do botão */
}

.service-request-form button:hover {
  background-color: #16324F;
}

.success-message {
  margin-top: 10px;
  text-align: center;
  color: green;
  font-weight: bold;
}

/* Estilos responsivos */
@media (max-width: 768px) {
  .service-request-form-container {
    padding: 30px 20px; /* Reduz o padding em telas menores */
  }

  .service-request-form-container h2 {
    font-size: 1.5rem; /* Ajusta o tamanho do título */
    margin-bottom: 20px; /* Reduz o espaçamento inferior */
  }

  .service-request-form input,
  .service-request-form textarea,
  .service-request-form select,
  .service-request-form button {
    max-width: 100%; /* Garante que os elementos ocupem toda a largura disponível */
    font-size: 14px; /* Reduz um pouco o tamanho da fonte */
  }

  .service-request-form textarea {
    min-height: 80px; /* Ajusta a altura mínima do textarea */
  }

  .service-request-form button {
    padding: 8px; /* Reduz o padding do botão */
  }
}

@media (max-width: 480px) {
  .service-request-form-container {
    padding: 20px 15px; /* Ainda menor para telas muito pequenas */
  }

  .service-request-form-container h2 {
    font-size: 1.2rem; /* Ajusta o tamanho do título para caber melhor */
  }
}