.termos {
    padding: 50px 200px; /* Espaçamento interno */
    max-width: 900px; /* Largura máxima */
    margin: 0 auto; /* Centraliza o conteúdo */
    text-align: justify; /* Justifica o texto */
    background-color: #ffffff; /* Fundo branco */
    border-radius: 8px; /* Bordas arredondadas */
}

/* Título principal */
.termos h1 {
    font-size: 2.5rem;
    color: #006494; /* Azul vibrante */
    margin-bottom: 20px;
    font-weight: bold;
    text-align: center; /* Centraliza o título */
}

/* Subtítulo (h2) */
.termos h2 {
    font-size: 1.8rem;
    color: #333; /* Cinza escuro */
    margin-bottom: 15px;
    font-weight: 600;
    border-bottom: 2px solid #006494; /* Linha azul abaixo */
    padding-bottom: 5px;
}

/* Parágrafos */
.termos p {
    font-size: 1.2rem;
    color: #555; /* Cinza médio */
    line-height: 1.8; /* Espaçamento entre as linhas */
    margin-bottom: 20px;
}

/* Links */
.termos a {
    color: #1e90ff; /* Azul vibrante */
    text-decoration: underline;
    font-weight: bold;
}

.termos a:hover {
    color: #ff4500; /* Laranja no hover */
    text-decoration: none;
}

/* Responsividade */
@media (max-width: 768px) {
    .termos {
        padding: 20px 15px; /* Ajusta o espaçamento interno */
    }

    .termos h1 {
        font-size: 2rem; /* Reduz o tamanho do título */
    }

    .termos h2 {
        font-size: 1.5rem; /* Reduz o tamanho do subtítulo */
    }

    .termos p {
        font-size: 1rem; /* Reduz o tamanho do texto */
    }
}
