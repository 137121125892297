/* Estilização geral do header */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #006494; /* Azul Flashdent */
    padding: 15px 20px;
    color: #fff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    
}

/* Estilo do título */
.header h1 {
    font-size: 1.8rem;
    font-weight: bold;
    margin: 0;
    
}

/* Estilização da lista da navbar */
.listHeader {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    
}

/* Estilização dos itens da navbar */
.itemHeader {
    margin: 0 13px;
}

/* Links da navbar */
.itemHeader a {
    text-decoration: none;
    color: #fff;
    font-size: 1rem;
    padding: 20px 20px; /* Espaçamento interno para parecer um botão */
    transition: all 0.3s ease-in-out; /* Suaviza o efeito */
    border: 2px solid transparent; /* Inicialmente invisível */
    font-size: 1.1rem;
}

/* Hover com efeito de borda azul escura */
.itemHeader a:hover {
    background-color: rgba(5, 40, 75, 0.534); /* Fundo translúcido azul escuro */
}


/* Estilização responsiva */
@media (max-width: 768px) {
    /* Ajustar o header para layout vertical */
    .header {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px 15px;
    }

    /* Título centralizado */
    .header h1 {
        font-size: 1.5rem;
        margin-bottom: 10px;
    }

    /* Ajustar a navbar */
    .listHeader {
        flex-direction: column; /* Alinhar os itens verticalmente */
        width: 100%; /* Ocupa toda a largura */
    }

    .itemHeader {
        margin: 5px 0; /* Espaçamento menor entre os itens */
        text-align: center;
        width: 100%; /* Ocupa toda a largura do container */
    }

    /* Links ajustados */
    .itemHeader a {
        display: block; /* Links ocupam toda a largura */
        padding: 10px 15px; /* Espaçamento interno reduzido */
        font-size: 1rem;
        border-radius: 5px; /* Cantos arredondados */
    }

    .itemHeader a:hover {
        background-color: rgba(5, 40, 75, 0.7); /* Fundo mais escuro no hover */
    }
}