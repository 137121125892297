.empresa {
    padding: 50px 200px; 
    max-width: 900px; 
    margin: 0 auto; 
    text-align: justify; 
    background-color: #ffffff; 
    border-radius: 8px; 
}

/* Título principal */
.empresa h1 {
    font-size: 2.5rem;
    color: #006494; 
    margin-bottom: 20px;
    font-weight: bold;
    text-align: center; 
}


.empresa h2 {
    font-size: 1.8rem;
    color: #333; 
    margin-bottom: 15px;
    font-weight: 600;
    border-bottom: 2px solid #006494; 
    padding-bottom: 5px;
}

/* Texto */
.empresa p {
    font-size: 1.5rem;
    color: #555; 
    line-height: 1.8; 
    margin-bottom: 20px;
}

.empresa ul {
    list-style-type: disc; 
    margin-left: 20px; 
    padding-left: 20px;
    color: #555; 
}

.empresa li {
    font-size: 1.5rem; 
    margin-bottom: 10px; 
    line-height: 1.8; 
}


.empresa a {
    color: #1e90ff; 
    text-decoration: underline;
    font-weight: bold;
}

.empresa a:hover {
    color: #ff4500; 
    text-decoration: none;
}


@media (max-width: 768px) {
    .empresa {
        padding: 20px 20px; 
    }

    .empresa h1 {
        font-size: 2rem; 
    }

    .empresa h2 {
        font-size: 1.5rem; 
    }

    .empresa p {
        font-size: 1rem; 
    }
}
