.contatos {
    padding: 50px 300px; /* Espaçamento interno */
    max-width: 900px; /* Largura máxima */
    margin: 0 auto; /* Centraliza o conteúdo e adiciona margem superior */
    text-align: center; /* Centraliza os textos */
    border-radius: 8px; /* Bordas arredondadas */
    background-color: #ffffff;
    font-size: 1.3rem
}

/* Título principal */
.contatos h1 {
    font-size: 2.5rem;
    color: #006494; /* Azul vibrante */
    margin-bottom: 20px;
    font-weight: bold;
}

/* Texto */
.contatos p {
    font-size: 1.5rem;
    color: #555; /* Cinza médio */
    line-height: 1.8; /* Espaçamento entre linhas */
    margin-bottom: 15px;
}

/* Links */
.contatos a {
    color: #1B98E0; /* Azul vibrante */
    text-decoration: none;
    font-weight: bold;
}

.contatos a:hover {
    color: #006494; /* Laranja no hover */
    text-decoration: none;
}

/* Responsividade */
@media (max-width: 768px) {
    .contatos {
        padding: 20px; /* Reduz o espaçamento interno em telas menores */
    }

    .contatos h1 {
        font-size: 2rem; /* Reduz tamanho do título */
    }

    .contatos p {
        font-size: 1rem; /* Reduz tamanho do texto */
    }
}
