/* Carrossel Contêiner */
.carrossel-container {
  display: flex;
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
  width: 100%; /* A largura do contêiner ocupa toda a tela */
  height: 500px; /* Defina a altura do carrossel conforme desejado */
  position: relative;
  margin: 0; /* Remove margens */
}

/* Carrossel */
.carrossel {
  width: 100%;
  max-width: 1400px;
  height: 500px;
  position: relative;
  overflow: hidden; /* Garante que apenas a imagem ativa esteja visível */
  display: flex; /* Alinha as imagens horizontalmente */
}

/* Estilos das imagens */
.carrossel img {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.9s ease-in-out, opacity 0.9s ease-in-out; /* Transição de opacidade mais rápida */
  pointer-events: none; /* Impede interação com imagens não visíveis */
  opacity: 0; /* Começa invisível para evitar qualquer efeito de fade durante a transição */
}

/* Imagem Ativa */
.carrossel img.active {
  opacity: 1; /* Torna a imagem ativa visível */
  z-index: 1; /* Coloca a imagem ativa na frente */
  transform: translateX(0); /* A imagem ativa fica centralizada */
}

/* Imagem Anterior */
.carrossel img.previous {
  opacity: 0; /* Torna a imagem anterior invisível */
  z-index: 0; /* Coloca a imagem anterior atrás da ativa */
  transform: translateX(-100%); /* Move para fora pela esquerda */
}

/* Imagem Próxima */
.carrossel img.next {
  opacity: 0; /* Torna a imagem próxima invisível */
  z-index: 0; /* Coloca a imagem próxima atrás da ativa */
  transform: translateX(100%); /* Move para fora pela direita */
}

/* Imagem Oculta */
.carrossel img.hidden {
  opacity: 0; /* As imagens não visíveis não interferem */
  z-index: -1; /* Coloca as imagens invisíveis atrás */
  transform: translateX(0); /* Não há movimento para fora, ficam atrás */
}

/* Controles (Botões de Navegação) */
.controls {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  pointer-events: none;
  z-index: 3; /* Botões acima das imagens */
}

/* Botões de Navegação */
button {
  pointer-events: auto; /* Permite clique nos botões */
  background: rgba(46, 46, 46, 0);
  color: white;
  border: none;
  padding: 15px;
  font-size: 30px;
  cursor: pointer;
  transition: background 0.5s;
  z-index: 3; /* Botões acima de tudo */
}

button:hover {
  background: rgba(0, 0, 0, 0.8);
}

.carrossel img {
  object-fit: cover;
}



/* Responsividade */
@media (max-width: 768px) {
  .carrossel-container {
    height: 300px;
  }

  .carrossel {
    height: 300px;
  }

  .controls button {
    font-size: 24px;
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .carrossel-container {
    height: 200px;
  }

  .carrossel {
    height: 200px;
  }

  .carrossel img {
    object-fit: contain;
  }
  .controls button {
    font-size: 20px;
    padding: 8px;
  }
}