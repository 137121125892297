/* Footer geral */
.footer {
    background-color: #006494;
    color: white;
    text-align: center;
    padding: 0px 0.1px;
    margin-top: 20px;
}

.footer-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    font-size: 1.2rem;
}

.footer-section {
    margin: 10px 0;
    text-align: left;

}

.footer-section h4 {
    font-size: 1rem;
    margin-bottom: 10px;
}

.footer-section a {
    text-decoration: none; /* Remove o sublinhado */
    color: #ffffff; /* Azul padrão para o link */ 
    transition: color 0.3s ease, text-shadow 0.3s ease; /* Transição suave */
  }
  
  .footer-section a:hover {
    color: #152730; /* Cor laranja ao passar o mouse */
    
  }
  
  .footer-section a:active {
    color: #ffffff; /* Cor ao clicar */
  }




.listFooter {
    list-style: none;
    padding: 0;
}

.itemFooter {
    margin: 10px 0;
}

.itemFooter a {
    color: white;
    text-decoration: none;
    transition: color 0.3s ease;
}

.itemFooter a:hover {
    color: #4f4916; /* Cor ao passar o mouse */
}

/* Rodapé inferior */
.footer-bottom {
    background-color: #13293D;
    margin-top: 10px;
    padding: 10px 30px;
    font-size: 0.9rem;
}

/* Responsividade */
@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
        align-items: center;
    }

    .footer-section {
        text-align: center;
        margin: 15px 0;
    }

    .footer-bottom {
        padding: 20px 15px;
    }
}

@media (max-width: 480px) {
    .footer-section h4 {
        font-size: 1rem;
    }

    .itemFooter a {
        font-size: 0.9rem;
    }

    .footer-bottom {
        padding: 15px 10px;
        font-size: 0.8rem;
    }
}